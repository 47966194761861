
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    // const group_type_data = ref([]);
    // const nob_data = ref([]);
    // const business_types_data = ref([]);
    const incoterms_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addRFQmodel = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_data = ref<Array<Supplier>>([]);
    const branch_data = ref([]);
    const consignee_branch_data = ref([]);
    const warehouse_branch_data = ref([]);
    const warehouse_list = ref([]);
    const maintain_by_list = ref([]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Product[]>([]);
    const dialogSelectProductTableVisible = ref(false);
    const productList = ref<Product[]>([]);
    // const myData = ref<Array<Product>>([]);
    const tableData = ref<Array<Product>>([]);

    const multipleSelectionPR = ref<pendingPR[]>([]);
    const dialogPendingPuchaseRequisition = ref(false);
    const pendingPRData = ref<Array<pendingPR>>([]);
    const selectPRArr = ref<Array<pendingPR>>([]);
    const myData = ref<Array<pendingPR>>([]);

    // interface UOM {
    //   uom_id: number;
    //   uom_display_name: string;
    // }

    const is_consignee_data = ref([
      { id: 1, name: "Yes" },
      { id: 0, name: "No" },
    ]);

    interface Product {
      purchase_product_master_id: string;
      purchase_indent_no: string;
      brand_name: string;
      product_id: string;
      product_name: string;
      required_qty: string;
      uom_name: string;
      pr_remarks: string;
    }
    const router = useRouter();

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    interface pendingPR {
      pr_detail_id: string;
      pr_no: string;
      category_name: string;
      category_short_name: string;
      mfg_company_id: string;
      mfg_company_name: string;
      description: string;
      pr_quantity: string;
      pending_quantity: string;
      uom: string;
      remark: string;
    }

    const formData = ref({
      is_consignee: 1,
      incoterms_select: ref<any>(""),
      consignee_select: "",
      warehouse_select: "",
      delivery_to: "",
      consignee_warehouse_select: "",
      warehouse_maintain_by: "",
      sjvd_date: new Date(),
    });

    const rules = ref<any>({});

    onMounted(async () => {
      getIncoterms();
      getWarehouse();
      getMaintainBy();
      getPendingPurchaseRequestsData();
      setCurrentPageBreadcrumbs("Create Request For Quotation", []);
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      //debugger;
      let pq_detail = multipleSelectionPR.value.map((item) => {
        return {
          pr_detail_id: item.pr_detail_id,
          mfg_company_id: item.mfg_company_id,
        };
      });

      if (pq_detail.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        user_id: user.user_id,
        pq_date: moment(data.sjvd_date).format("YYYY-MM-DD"),
        incoterm_id: data.incoterms_select,
        buyer_yn: data.is_consignee,
        buyer_company_id: data.is_consignee === 0 ? 0 : data.consignee_select,
        buyer_company_branch_id:
          data.is_consignee === 0 ? 0 : data.consignee_warehouse_select,
        warehouse_company_branch_id:
          data.is_consignee === 1 ? 0 : data.warehouse_select,
            // ? 0
            // : data.incoterms_select === 1
            // ? 0
            // : data.warehouse_select,
        delivery_address: data.delivery_to,
        own_user_id: data.maintain_by_select,
        pq_detail: pq_detail,
      };
      console.log(JSON.stringify(db_data));
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_PURCHASE_REQUEST_QUOTATION, db_data)
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                html: ""+
                      data?.pq_no+
                      "<br>Purchase quotation created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-rfq-listing" });
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      //debugger;
      loading.value = true;

      if (formData.value["is_consignee"] === 1) {
        rules.value = {
          incoterms_select: [
            {
              required: true,
              message: "Incoterm is required",
              trigger: "change",
            },
          ],
          consignee_select: [
            {
              required: true,
              message: "Consignee is required",
              trigger: "change",
            },
          ],
          // delivery_to: [
          //   {
          //     required: true,
          //     message: "Delivery address is required",
          //     trigger: "change",
          //   },
          // ],
          consignee_warehouse_select: [
            {
              required: true,
              message: "Consignee Warehouse/branch is required",
              trigger: "change",
            },
          ],
          sjvd_date: [
            {
              required: true,
              message: "Date is required",
              trigger: "change",
            },
          ],
          maintain_by_select: [
            {
              required: true,
              message: "Maintain by is required",
              trigger: "change",
            },
          ],
        };
      } else if (formData.value["is_consignee"] === 0) {
        rules.value = {
          incoterms_select: [
            {
              required: true,
              message: "Incoterm is required",
              trigger: "change",
            },
          ],
          warehouse_select: [
            {
              required: true,
              message: "Warehouse is required",
              trigger: "change",
            },
          ],
          // delivery_to: [
          //   {
          //     required: true,
          //     message: "Delivery address is required",
          //     trigger: "change",
          //   },
          // ],
          sjvd_date: [
            {
              required: true,
              message: "Date is required",
              trigger: "change",
            },
          ],
          maintain_by_select: [
            {
              required: true,
              message: "Maintain by is required",
              trigger: "change",
            },
          ],
        };
      }

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      setTimeout(() => {
        formRef?.value?.validate(async (valid) => {
          if (valid) {
            await setCompanyData(formData.value);
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 300);
    };

    // Sachin check code
    async function getCompanyList(query: string) {
      //debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          //debugger;
          consignee_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList(callFrom) {
      let values = {};
      if (callFrom === "supplier") {
        values = {
          company_id: formData.value["supplier_select"],
        };
      } else if (callFrom === "consignee") {
        values = {
          company_id: formData.value["consignee_select"],
          company_branch_id: 0,
          page: 1,
          records_per_page: 1000,
        };
      } else {
        values = {
          company_id: 1,
        };
      }
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          //debugger;
          if (callFrom === "supplier") {
            branch_data.value = data;
          } else if (callFrom === "consignee") {
            consignee_branch_data.value = data.result_list;
          } else {
            warehouse_branch_data.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getIncoterms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_INCOTERM)
        .then(({ data }) => {
          //debugger;
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getWarehouse() {
      var user = JSON.parse(JwtService.getToken());
      let params = {};
      await store
        .dispatch(ActionsFi.CUST_GET_WAREHOUSE_LIST, params)
        .then(({ data }) => {
          //debugger;
          warehouse_list.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getMaintainBy() {
      var user = JSON.parse(JwtService.getToken());
      let params = {};
      await store
        .dispatch(ActionsFi.CUST_GET_MAINTAIN_BY_LIST, params)
        .then(({ data }) => {
          //debugger;
          maintain_by_list.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: Product[]) => {
      multipleSelection.value = val;
    };

    const handleSelectionChangePendingPR = (val: pendingPR[]) => {
      multipleSelectionPR.value = val;
    };

    const onCancel = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelectionPR.value = [];
      dialogPendingPuchaseRequisition.value = false;
    };

    // const onCancel = (rows?: pendingPR[]) => {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       // TODO: improvement typing when refactor table
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-expect-error
    //       multipleTableRef.value!.toggleRowSelection(row, undefined);
    //     });
    //   } else {
    //     multipleTableRef.value!.clearSelection();
    //   }
    //   multipleSelectionPR.value = [];
    //   dialogPendingPuchaseRequisition.value = false;
    // };

    async function getTableData() {
      var user = JSON.parse(JwtService.getToken());

      let params = {
        user_id: user.user_id,
      };
      //debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_INDENT_LIST, params)
        .then(({ data }) => {
          //debugger;
          data.map((product) => {
            product.required_qty =
              product.required_qty + " " + product.uom_name;
          });
          tableData.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const openDialog = () => {
      dialogPendingPuchaseRequisition.value = true;
    };

    const onContinue = () => {
      //debugger;
      if (multipleSelectionPR.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one purchase request!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        myData.value = multipleSelectionPR.value;
        dialogSelectProductTableVisible.value = false;
        dialogPendingPuchaseRequisition.value = false;
      }
    };

    async function getPendingPurchaseRequestsData() {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      let params = {
        user_id: user.user_id,
        page: 1,
        records_per_page: 100,
      };
      //debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_PURCHASE_REQUISITION_LIST, params)
        .then(({ data }) => {
          //debugger;
          data.result_list.map((pendingPR) => {
            pendingPR.pr_quantity = pendingPR.pr_quantity + " " + pendingPR.uom;
            pendingPR.pending_quantity =
              pendingPR.pending_quantity + " " + pendingPR.uom;

            if (pendingPR.category_short_name != "") {
              pendingPR.category_name =
                pendingPR.category_name + " - " + pendingPR.category_short_name;
            }
            if (pendingPR.remark == "") {
              pendingPR.remark = "--";
            }
          });
          pendingPRData.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      industry_types_data,
      roc_list_data,
      formData,
      rules,
      submit,
      formRef,
      loading,
      addRFQmodel,
      supplier_data,
      getCompanyList,
      is_consignee_data,
      consignee_data,
      branch_data,
      consignee_branch_data,
      warehouse_branch_data,
      getBranchList,
      dialogSelectProductTableVisible,
      dialogPendingPuchaseRequisition,
      tableData,
      myData,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      handleSelectionChangePendingPR,
      onContinue,
      onCancel,
      getTableData,
      openDialog,
      incoterms_data,
      getIncoterms,
      getWarehouse,
      warehouse_list,
      pendingPRData,
      selectPRArr,
      maintain_by_list,
      getMaintainBy,
    };
  },
});
